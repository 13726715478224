<template>
  <div class="404">
    <h3>Page not found. Sorry.</h3>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: '404'
};
</script>
